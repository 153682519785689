import _ from 'lodash'

export default {
  metaInfo () {
    const seo = {}

    seo.title = this.campaign.name
    seo.meta = []

    _.forEach(this.campaign.seo.metas, (tag) => {
      seo.meta.push({
        vmid: tag.name,
        name: tag.name,
        content: tag.content,
      })
    })

    return seo
  },
}
