<template>
  <section class="min-h-screen flex items-stretch text-white" v-if="!$store.state.building">
    <div
      class="lg:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center"
      :style="{ 'background-image': `url(${campaign.template.sidebars.left.background.image})` }"
    >
      <div class="absolute bg-black opacity-60 inset-0 z-0"></div>

      <div class="w-full px-24 z-10">
        <h1 class="text-5xl font-bold text-left tracking-wide">{{ campaign.title }}</h1>

        <p class="text-3xl my-4">
          {{ campaign.description }}
        </p>
      </div>
      <!--/ Title && Description -->

      <div class="bottom-0 absolute p-4 text-center right-0 left-0 flex justify-center space-x-4">
        <social-network
          v-for="(username, social_network) in campaign.socialmedia"
          :key="social_network"
          :username="username"
          :social_network="social_network" />
      </div>
      <!--/ Social Network -->

    </div>
    <!--/ Sidebar Left -->

    <div
      class="lg:w-1/2 w-full flex items-center justify-center md:px-16 px-0 z-0"
      :style="{ 'background-color': campaign.template.sidebars.right.background.color }"
    >
      <div
        class="absolute lg:hidden z-10 inset-0 bg-gray-500 bg-no-repeat bg-cover items-center"
        :style="{ 'background-image': `url(${campaign.template.sidebars.left.background.image})` }"
      >
        <div class="absolute bg-black opacity-60 inset-0 z-0" />
      </div>
      <!--/ Background Responsive  -->

      <div class="w-full py-6 z-20">
        <h1 class="my-6">
          <img :src="campaign.template.logo" class="w-auto h-7 sm:h-8 mx-auto" width="300" height="150" />
        </h1>

        <slot />
      </div>
    </div>
    <!--/ Sidebar Right -->

  </section>
</template>

<script>
import SocialNetwork from '@/views/templates/basic/components/SocialNetwork'

export default {
  name: 'Layout',

  components: {
    SocialNetwork,
  },
}
</script>
