<template>
  <div>
    <component :is="asyncComponent" :username="username" />
  </div>
</template>

<script>
export default {
  name: 'SocialNetwork',

  props: {
    username: {
      type: String,
      required: true,
    },
    social_network: {
      type: String,
      required: true,
    },
  },

  computed: {
    asyncComponent () {
      if (this.social_network) {
        const name = this.social_network.charAt(0).toUpperCase() + this.social_network.slice(1)

        return () => import('@/components/SocialNetwork/' + name)
      } else {
        return null
      }
    },
  },
}
</script>
